import Layout from "components/Layout";
import Page from "components/Page";
import SEO from "components/Seo";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";

function AboutPage() {
  const data = useStaticQuery(graphql`
    query AboutQuery {
      allContentfulPageAbout {
        edges {
          node {
            pageTitle
            heroSectionHeading
            heroSectionImage {
              fluid(maxWidth: 800, quality: 90) {
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
              }
            }
            bodySectionContent {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
  `);
  const dataNode = data.allContentfulPageAbout.edges[0].node;
  return (
    <Layout>
      <SEO
        // metaDescription={dataNode.pageDescription}
        title={dataNode.pageTitle}
      />
      <Page data={dataNode} />
    </Layout>
  );
}

export default AboutPage;
